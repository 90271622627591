import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { getInternalURL } from '../common/link';
import { BodyArticles } from '../components/layout/body/articles';
import { BodyVideos } from '../components/layout/body/videos';
import { CoverTitle } from '../components/cover/title';

export const query = graphql`
  query TvTopicQuery($id: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsTvTopic(
      id: { eq: $id }
    ) {
      model {
        ...PageModel
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
    }
    shows: allDatoCmsTvShow(
      filter: {slug: {ne: null}, topics: {elemMatch: {id: {eq: $id}}}}
    ) {
      nodes {
        ...Show
      }
    }
  }
`;

const TvTopic = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const shows:any = path(['shows', 'nodes'], data);
  const page = prop('page', data);
  const site = prop('site', data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });
  const pageURL = settings.siteUrl + getInternalURL(page);

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
      />
      <BodyVideos shows={shows} heading={page.name} />
    </Fragment>
  );
};

export default TvTopic;