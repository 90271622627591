
import React from 'react';
import { ShowList } from '../../../show/showList';
import { Container, Content } from './videos.styles';
import { BodyVideosProps } from './videos.types';

export const BodyVideos = ({shows,heading}:BodyVideosProps) => {
  return (
    <Container>
      <Content>
        <ShowList items={shows} heading={heading} />
      </Content>
    </Container>
  )
};